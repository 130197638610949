/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class EmailHtmlComposerPreviewComponent extends Component {
  @action loadHtml(iframe) {
    iframe.contentDocument.open();
    iframe.contentDocument.write(sanitizeHtml(this.args.html, { config: 'email' }));
    iframe.contentDocument.close();
  }
}
